import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {
  LOGIN,
  HOME,
  ROUTE_GUARD_TYPE_AUTH,
  ROUTE_GUARD_TYPE_UNAUTH,
} from './index';

const RouteGuard = ({children, isAuthenticated, guard, ...rest}) => {
  const renderProp = ({location}) => {
    if (guard === ROUTE_GUARD_TYPE_AUTH && !isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: LOGIN.path,
            state: {from: location},
          }}
        />
      );
    } else if (guard === ROUTE_GUARD_TYPE_UNAUTH && isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: HOME.path,
            state: {from: location},
          }}
        />
      );
    }

    return children;
  };

  return <Route {...rest} render={renderProp} />;
};

export default RouteGuard;
