import {createSelector} from 'reselect';

export const getVerificationCode = ({forgotPasswordVerificationCode}) =>
  forgotPasswordVerificationCode;
export const getIsForgotPasswordSendCodeSuccess = createSelector(
  getVerificationCode,
  (forgotPasswordVerificationCode) =>
    forgotPasswordVerificationCode.isForgotPasswordSendCodeSuccess,
);
export const getUserInfo = createSelector(
  getVerificationCode,
  (forgotPasswordVerificationCode) => forgotPasswordVerificationCode.userInfo,
);
