import {combineReducers} from 'redux';
import {
  FORCE_CHANGE_PASSWORD_FAILURE,
  FORCE_CHANGE_PASSWORD_SUCCESS,
} from './constants';

const isChangedPasswordSuccess = (state = false, action) => {
  switch (action.type) {
    case FORCE_CHANGE_PASSWORD_SUCCESS:
      return true;
    case FORCE_CHANGE_PASSWORD_FAILURE:
      return false;
    default:
      return state;
  }
};

const userInfo = (state = null, action) => {
  switch (action.type) {
    case FORCE_CHANGE_PASSWORD_SUCCESS:
      return action.user;
    default:
      return state;
  }
};

const isChangedPasswordError = (state = null, action) => {
  switch (action.type) {
    case FORCE_CHANGE_PASSWORD_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export default combineReducers({
  isChangedPasswordError,
  userInfo,
  isChangedPasswordSuccess,
});
