import Amplify, {Auth} from 'aws-amplify';
import {FORCE_CHANGE_PASSWORD_SUCCESS} from './constants';

export const forceChangePassword = (userName, newPassword) => {
  return (dispatch) => {
    return Auth.completeNewPassword(userName, newPassword)
      .then((user) => {
        console.log(user);
        dispatch({type: FORCE_CHANGE_PASSWORD_SUCCESS, user});
      })
      .catch((err) => console.log(err));
  };
};
