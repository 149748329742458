import {createSelector} from 'reselect';

export const getAuth = ({auth}) => auth;
export const getAuthIsAuthenticated = createSelector(
  getAuth,
  (auth) => auth.isAuthenticated,
);
export const getAuthIsPasswordChangeRequired = createSelector(
  getAuth,
  (auth) => auth.isPasswordChangeRequired,
);
export const loginErrorDetails = createSelector(
  getAuth,
  (auth) => auth.getLoginError,
);
export const isLoginFailed = createSelector(
  getAuth,
  (auth) => auth.isLoginFailed,
);
