import React from 'react';
import moment from 'moment';

export const serviceEngineerColumns = [
  {
    title: 'Employee Code',
    dataIndex: 'empCode',
    key: 'empCode',
    sorter: {
      compare: (a, b) => a.empCode / b.empCode,
    },
  },
  {
    title: 'Engineer Name',
    dataIndex: 'serviceEngineerName',
    key: 'serviceEngineerName',
    sorter: {
      compare: (a, b) => a.serviceEngineerName / b.serviceEngineerName,
    },
  },

  {
    title: 'Punch In',
    dataIndex: 'punchIn',
    key: 'empCode',
    render: (punchIn) => {
      return punchIn ? (
        <p>{moment(punchIn).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.punchIn) / moment(b.punchIn),
    },
  },
  {
    title: 'Punch Out',
    dataIndex: 'punchOut',
    key: 'empCode',
    render: (punchOut) => {
      return punchOut ? (
        <p>{moment(punchOut).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.punchOut) / moment(b.punchOut),
    },
  },
  {
    title: 'Services',
    dataIndex: 'servicesCount',
    key: 'empCode',
    sorter: {
      compare: (a, b) => a.servicesCount / b.servicesCount,
    },
  },
  {
    title: 'Last Status',
    dataIndex: 'lastStatus',
    key: 'empCode',
    sorter: {
      compare: (a, b) => a.lastStatus / b.lastStatus,
    },
  },
];
