export const SERVICEFORM_REQUEST = 'SERVICEFORM_REQUEST';
export const SERVICEFORM_SUCCESS = 'SERVICEFORM_SUCCESS';
export const SERVICEFORM_FAILURE = 'SERVICEFORM_FAILURE';
export const FORMSPECIFICDATA_REQUEST = 'FORMSPECIFICDATA_REQUEST';
export const FORMSPECIFICDATA_SUCCESS = 'FORMSPECIFICDATA_SUCCESS';
export const FORMSPECIFICDATA_FAILURE = 'FORMSPECIFICDATA_FAILURE';
export const SAVEFIRFORM_REQUEST = 'SAVEFIRFORM_REQUEST';
export const SAVEFIRFORM_SUCCESS = 'SAVEFIRFORM_SUCCESS';
export const SAVEFIRFORM_FAILURE = 'SAVEFIRFORM_FAILURE';
export const RESET_DEFAULT = 'RESET_DEFAULT';
export const DOWNLOADING_PDF = 'DOWNLOADING_PDF';
export const SAVE_PDF = 'SAVE_PDF';
export const ERROR_PDF_DOWNLOAD = 'ERROR_PDF_DOWNLOAD';

export const END_TRIP_DATE_REQUEST = 'END_TRIP_DATE_REQUEST';
export const END_TRIP_DATE_SUCCESS = 'END_TRIP_DATE_SUCCESS';
export const END_TRIP_DATE_FAILURE = 'END_TRIP_DATE_FAILURE';

export const MALFUNCTION_DATE_REQUEST = 'MALFUNCTION_DATE_REQUEST';
export const MALFUNCTION_DATE_SUCCESS = 'MALFUNCTION_DATE_SUCCESS';
export const MALFUNCTION_DATE_FAILURE = 'MALFUNCTION_DATE_FAILURE';
