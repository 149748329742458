import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {Table, Col, Row, Button, Input} from 'antd';
import {getserviceEngineer} from '../../modules/serviceEngineer/action';
import {useSelector, useDispatch} from 'react-redux';
import {
  getServiceEngineerData,
  isServiceEngineerFetching,
} from '../../modules/serviceEngineer/selectors';
import {Parser} from 'json2csv';

import {getAuthUserData} from '../../modules/auth/user/selector';
import {serviceEngineerColumns} from '../../data/serviceEngineerColumns';
import ServiceEngineerModal from '../common/ServiceEngineerModal';

export const ServiceEngineer = () => {
  const authUser = useSelector(getAuthUserData);
  const [filterData, setFilterData] = useState();
  const downloadDataDumpClick = () => {
    if (csvData) {
      const headers = Object.keys(csvData[0]);

      const parser = new Parser(headers);
      const csv = parser.parse(csvData);
      var blob = new Blob([csv], {type: 'text/csv'});
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveBlob(
          blob,
          `Service Engineer Details_${new Date()}.csv`,
        );
      else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
        a.download = `ActiveServicesReport_${new Date()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  };
  const filterDataOnEmpId = (value) => {
    if (value.length > 0 && filterData) {
      setFilterData(
        serviceEngineerData.filter((s) => s.empCode.includes(value)),
      );
    } else {
      setFilterData(serviceEngineerData);
    }
  };
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const serviceEngineerData = useSelector(getServiceEngineerData);
  const fetchingData = useSelector(isServiceEngineerFetching);

  const getDetails = (userData, record) => {
    setVisible(!visible);
    setModalData(record);
  };

  const updatedColumns = [
    ...serviceEngineerColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'empCode',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getserviceEngineer(authUser.accessToken));
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    if (serviceEngineerData) {
      setFilterData(serviceEngineerData);
      setCsvData(
        serviceEngineerData.map(function (item) {
          return {
            'Employee Code': item.empCode,
            'Engineer Name': item.serviceEngineerName,
            'Punch In': item.punchIn,
            'Punch Out': item.punchOut,
            'Last Status': item.lastStatus,
          };
        }),
      );
    }
  }, [serviceEngineerData]);
  return (
    <MainLayout>
      <div className="contentLayout">
        <div>
          <Row>
            <Col span={6}>
              <span className="contentHeader">Service Engineer Details</span>
            </Col>
            <Col span={10}>
              <Button
                onClick={() => downloadDataDumpClick()}
                style={{
                  background: '#38a169',
                  border: '#38a169',
                  fontWeight: '550',
                  float: 'left',
                  marginRight: '10px',
                }}
                type="primary">
                Export Data
              </Button>
            </Col>
            <Col span={8}>
              <Input
                onChange={(e) => filterDataOnEmpId(e.target.value)}
                placeholder="Search By Employee Code"
                style={{float: 'right'}}
                width="100px"
              />
            </Col>
          </Row>
        </div>
        <ServiceEngineerModal
          data={modalData}
          onCancel={() => setVisible(false)}
          //onCreate={() => handleCreate()}
          visible={visible}
        />

        <Table
          columns={updatedColumns}
          dataSource={filterData}
          loading={fetchingData}
          pagination={true}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
