import React from 'react';
import {Modal, Form, Collapse, Input, Checkbox, Table} from 'antd';
import {equipmetHistoryColumns} from '../../data/equipmentHistoryColumns';
import moment from 'moment';

const {Panel} = Collapse;

const ModalForm = ({visible, onCancel, onCreate, data}) => {
  return (
    <Modal
      cancelText="Close"
      footer={null}
      onCancel={onCancel}
      title="Service Form Details"
      visible={visible}
      width={1000}>
      <Form>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Customer Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Contact Person</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader"> Customer Name</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .contactPerson
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .customerName
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Customer Location</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader"> Contact Number</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster.district
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .contactNumber
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Machine Location</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .machineLocation
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Service Details" key="2">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Customer Call Id</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader"> Follow Up Call Id</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .customerCallNo
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .followupCallNo
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Call Date & Time</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader">Call Sub Type</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? moment(
                                  data.serviceDetail.readOnlyFieldMaster
                                    .complaintReceivedDate,
                                  'DD/MM/YYYY hh:mm A',
                                ).format('DD/MM/YYYY hh:mm A')
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .callSubType
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Call Type</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader">Call SubCategory</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .typeOfService
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .callSubCategory
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Call Category</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader">Service Description</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .callCategory
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .natureOfComplaint
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Actual Malfunction Date & Time</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? moment(
                                  data.serviceDetail.readOnlyFieldMaster
                                    .failureDate,
                                  'DD/MM/YYYY hh:mm A',
                                ).format('DD/MM/YYYY hh:mm A')
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Equipment Details" key="3">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Last HMR Reading</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader"> Last HMR Date</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster
                                  .hmrFromDateValue
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? moment(
                                  data.serviceDetail.readOnlyFieldMaster
                                    .hmrFromDate,
                                ).format('DD/MM/YYYY hh:mm A')
                              : ''
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    <Checkbox
                      checked={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster.warranty
                              : false
                            : false
                          : false
                      }
                      defaultChecked={false}
                      disabled
                    />
                    {'  '}Warranty
                  </td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader">
                    <Checkbox
                      checked={
                        data
                          ? data.serviceDetail
                            ? data.serviceDetail.readOnlyFieldMaster
                              ? data.serviceDetail.readOnlyFieldMaster.breakdown
                              : false
                            : false
                          : false
                      }
                      defaultChecked={false}
                      disabled
                    />
                    {'  '}Breakdown
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="modalTable">
              <span className="modalTableHeader">
                EQUIPMENT SERVICE NUMBER :
                {data.equipmentNumber ? data.equipmentNumber : ''}
              </span>
              <Table
                columns={equipmetHistoryColumns}
                dataSource={
                  data
                    ? data.serviceDetail
                      ? data.serviceDetail.readOnlyFieldMaster
                        ? data.serviceDetail.readOnlyFieldMaster
                            .equipmentServiceHistories
                        : []
                      : []
                    : []
                }
                loading={false}
                pagination={true}
                scroll={{x: 440}}
                style={{marginTop: '10px'}}
              />
            </div>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};
export default ModalForm;
