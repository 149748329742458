export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_Failure = 'LOGOUT_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_SESSSION = 'UPDATE_SESSSION';
export const FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';
