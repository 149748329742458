import React from 'react';
import moment from 'moment';
export const equipmetHistoryColumns = [
  {
    title: 'Followup Call Id',
    dataIndex: 'followUpCallId',
    key: 'followUpCallId',
    sorter: (a, b) => {
      a = a.followUpCallId === null ? '' : a.followUpCallId;
      b = b.followUpCallId === null ? '' : b.followUpCallId;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Call Type',
    dataIndex: 'callType',
    key: 'callType',
    sorter: (a, b) => {
      a = a.callType === null ? '' : a.callType;
      b = b.callType === null ? '' : b.callType;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Date Created',
    dataIndex: 'createDataTime',
    key: 'followupCallId',
    render: (createDataTime) => {
      return <p>{moment(createDataTime).format('DD/MM/YYYY hh:mm A')}</p>;
    },
    sorter: (a, b) =>
      moment(a.createDataTime).unix() - moment(b.createDataTime).unix(),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'followupCallId',
    sorter: {
      compare: (a, b) => {
        a = a.description === null ? '' : a.description;
        b = b.description === null ? '' : b.description;
        return a.localeCompare(b);
      },
    },
  },
  {
    title: 'Machine Address',
    dataIndex: 'machineAddress',
    key: 'followupCallId',
    sorter: {
      compare: (a, b) => {
        a = a.machineAddress === null ? '' : a.machineAddress;
        b = b.machineAddress === null ? '' : b.machineAddress;
        return a.localeCompare(b);
      },
    },
  },
];
