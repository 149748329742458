import RouteGuard from './RouteGuard';
import Login from '../login/Login';
import {Dashboard} from '../home/Dashboard';
import {ETools} from '../eTools/ETools';
import NotFound from '../common/NotFound';
import {Notifications} from '../notifications/Notifications';
import ForceChangePasswordContainer from '../../containers/ForceChangePasswordContainer';
import {ForgotPassword} from '../forgotPassword/ForgotPassword';
import {ForgotPasswordVerificationCode} from '../forgotPassword/ForgotPasswordVerificationCode';
import {ServiceEngineer} from '../serviceEngineer/ServiceEngineer';
import {RSMList} from '../rsmList/RSMList';
import UserManagement from '../userManagement/UserManagement';
import {ServiceForms} from '../serviceForms/ServiceForms';
import {Report} from '../report/Report';
import {ServiceCallClose} from '../serviceCallClose/ServiceCallClose';
import {ExpertChats} from '../expertChat/ExpertChat';

export {RouteGuard};

// Must be authenticated to access the route
export const ROUTE_GUARD_TYPE_AUTH = 'AUTH';
// Must be unauthenticated to access the route(/login, /register)
export const ROUTE_GUARD_TYPE_UNAUTH = 'UNAUTH';
export const ROUTE_GUARD_TYPE_GENERAL = 'GENERAL';

export const LOGIN = {
  id: 'Login',
  path: '/login',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: Login,
};
export const FORCECHANGEPASSWORD = {
  id: 'ForceChangePassword',
  path: '/forceChangePassword',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: ForceChangePasswordContainer,
};
export const FORGOTPASSWORD = {
  id: 'ForgotPassword',
  path: '/forgotPassword',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: ForgotPassword,
};
export const FORGOTPASSWORDVERIFICATIONCODE = {
  id: 'ForgotPasswordVerificationCode',
  path: '/forgotPasswordVerificationCode',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: ForgotPasswordVerificationCode,
};

export const HOME = {
  id: 'Dashboard',
  path: '/',
  exact: true,
  guard: ROUTE_GUARD_TYPE_AUTH,
  component: Dashboard,
};

export const ROUTES = [
  {...HOME},
  {...LOGIN},
  {...FORCECHANGEPASSWORD},
  {...FORGOTPASSWORD},
  {...FORGOTPASSWORDVERIFICATIONCODE},
  {
    id: 'RSMList',
    path: '/rsmList',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: RSMList,
  },
  {
    id: 'ETools',
    path: '/eTools',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ETools,
  },
  {
    id: 'UserManagement',
    path: '/userManagement',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: UserManagement,
  },
  {
    id: 'Servcie Forms',
    path: '/serviceForms',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ServiceForms,
  },
  {
    id: 'ServiceCallClose',
    path: '/serviceCallClose',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ServiceCallClose,
  },
  {
    id: 'Reports',
    path: '/reports',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Report,
  },
  {
    id: 'Notifications',
    path: '/notifications',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Notifications,
  },
  {
    id: 'ETools',
    path: '/eTools',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ETools,
  },
  {
    id: 'ServiceEngineer',
    path: '/serviceEngineer',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ServiceEngineer,
  },
  {
    id: 'ExperChat',
    path: '/expertChat',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ExpertChats,
  },
  {
    id: 'NotFound',
    path: '*',
    guard: ROUTE_GUARD_TYPE_GENERAL,
    component: NotFound,
  },
];
