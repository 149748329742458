import React from 'react';
import {Select, Space, DatePicker, Button, Row, Col, Input} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const {Option} = Select;

const DataFilterBox = (props) => {
  return (
    <div className="contentLayout" style={{marginTop: '10px'}}>
      <Row>
        <Col span={24}>
          <fieldset className="contentFieldSet">
            <legend>{props.title}</legend>
            <Row
              style={{
                fontSize: 'small',
                fontWeight: 'bold',
                color: '#000000',
              }}>
              {props.items.map((item) => {
                return (
                  <Col key={item.fieldId} span={4} style={{color: '#797878'}}>
                    {item.fieldTitle}
                  </Col>
                );
              })}
            </Row>
            <Row>
              {props.items.map((item) => {
                switch (item.fieldType) {
                  case 'Select':
                    return (
                      <Col key={item.fieldId} span={4}>
                        <Select
                          defaultValue={item.value}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          key={item.fieldId}
                          listHeight={200}
                          listItemHeight={10}
                          onChange={(e) => item.onChange(e)}
                          optionFilterProp="children"
                          //placeholder="Select a state"
                          showSearch
                          style={{
                            width: 150,
                            fontWeight: 'normal',
                            marginTop: item.fieldMargin,
                          }}
                          value={item.value}>
                          {!item.options ? (
                            <Option key="ALL" value="ALL">
                              All
                            </Option>
                          ) : (
                            item.options.map((option) => {
                              return (
                                <Option key={option.key} value={option.key}>
                                  {option.value}
                                </Option>
                              );
                            })
                          )}
                        </Select>
                      </Col>
                    );
                  case 'DatePicker': {
                    return (
                      <Col key={item.fieldId} span={4}>
                        <Space direction="vertical">
                          <DatePicker
                            clearIcon={null}
                            defaultValue={moment(item.value, 'DD/MM/YYYY')}
                            format={'DD/MM/YYYY'}
                            onChange={(e) => item.onChange(e)}
                            style={{width: 150, marginTop: item.fieldMargin}}
                            value={moment(item.value, 'DD/MM/YYYY')}
                          />
                        </Space>
                      </Col>
                    );
                  }
                  case 'Button': {
                    return (
                      <Col key={item.fieldId} style={{padding: '0pt 10pt'}}>
                        <Button
                          onClick={() => item.onClick()}
                          style={{
                            background: '#fd560eb5',
                            border: '#fd560eb5',
                            fontWeight: '550',
                            marginTop: item.fieldMargin,
                          }}
                          type="primary">
                          {item.fieldName}
                        </Button>
                      </Col>
                    );
                  }
                  case 'Label': {
                    return (
                      <Col
                        key={item.fieldId}
                        span={2.5}
                        style={{
                          fontSize: '14px',
                          fontWeight: 550,
                          color: '#797878',
                          marginTop: item.fieldMargin,
                          marginRight: '5px',
                        }}>
                        {item.fieldName}
                      </Col>
                    );
                  }
                  case 'Input': {
                    return (
                      <Col key={item.fieldId} span={4}>
                        <Input
                          onChange={item.onChange}
                          placeholder={item.placeholderTitle}
                          style={{marginTop: item.fieldMargin}}
                          value={item.value}
                        />
                      </Col>
                    );
                  }
                  case 'Col': {
                    return <Col key={item.fieldId} span={1}></Col>;
                  }
                  default:
                    return '';
                }
              })}
            </Row>
          </fieldset>
        </Col>
      </Row>
    </div>
  );
};

DataFilterBox.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

export default DataFilterBox;
