import React from 'react';
import moment from 'moment';

export const activeServicesColumns = [
  {
    title: 'Followup Call Id',
    dataIndex: 'followupCallId',
    key: 'followupCallId',
    sorter: {
      compare: (a, b) => a.followupCallId / b.followupCallId,
    },
  },
  {
    title: 'Customer Call Id',
    dataIndex: ['serviceDetail', 'readOnlyFieldMaster', 'customerCallNo'],
    key: 'followupCallId',
    sorter: {
      compare: (a, b) =>
        a.serviceDetail.readOnlyFieldMaster.customerCallNo /
        b.serviceDetail.readOnlyFieldMaster.customerCallNo,
    },
  },

  {
    title: 'Dealer Name',
    dataIndex: ['serviceDetail', 'readOnlyFieldMaster', 'serviceBranch'],
    key: 'followupCallId',
    sorter: (a, b) => {
      a = a.customerName === null ? '' : a.customerName;
      b = b.customerName === null ? '' : b.customerName;
      return a.localeCompare(b);
    },
  },
  // {
  //   title: 'Call Status',
  //   dataIndex: 'tripStatus',
  //   key: 'followupCallId',
  //   sorter: {
  //     compare: (a, b) =>
  //       a.serviceDetail.readOnlyFieldMaster.tripStatus /
  //       b.serviceDetail.readOnlyFieldMaster.tripStatus,
  //   },
  // },
  {
    title: 'Call Type',
    dataIndex: ['serviceDetail', 'readOnlyFieldMaster', 'typeOfService'],
    key: 'followupCallId',
    sorter: {
      compare: (a, b) =>
        a.serviceDetail.readOnlyFieldMaster.typeOfService /
        b.serviceDetail.readOnlyFieldMaster.typeOfService,
    },
  },
  {
    title: 'Assigned Service Engineer',
    dataIndex: ['serviceDetail', 'readOnlyFieldMaster', 'serviceEngineerName'],
    key: 'followupCallId',
    sorter: {
      compare: (a, b) =>
        a.serviceDetail.readOnlyFieldMaster.serviceEngineerName /
        b.serviceDetail.readOnlyFieldMaster.serviceEngineerName,
    },
  },

  {
    title: 'Equipment Number',
    dataIndex: 'equipmentNumber',
    key: 'followupCallId',
    sorter: {
      compare: (a, b) => a.equipmentNumber.localeCompare(b.equipmentNumber),
    },
  },
];
