import {date} from 'yup';
import React from 'react';
import moment from 'moment';

export const userInfoColumns = [
  {
    title: 'Created Name',
    dataIndex: 'createdBy',
    key: 'email',
    sorter: {
      compare: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdOn',
    key: 'email',
    render: (createdOn) => {
      return createdOn ? (
        <p>{moment(createdOn).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.createdOn) / moment(b.createdOn),
    },
  },
];

export const data = [
  {
    name: 'Akash Kumar Gupta',
    role: 'Dealer',
    email: 'akash.kumar.gupta@pwc.com',
    mobileNumber: 912509888,
    createdBy: 'Amit Pal',
    creationOn: '23/09/2020',
  },
  {
    name: 'Raju Kumar Sahu',
    role: 'Technician',
    email: 'Raju.a@pwc.com',
    mobileNumber: 912509888,
    createdBy: 'Akash Kumar Gupta',
    creationOn: '23/09/2020',
  },
  {
    name: 'Alok Kumar',
    role: 'Dealer',
    email: 'alok.kumar@gmail.com',
    mobileNumber: 962309888,
    createdBy: 'Amit Hansda',
    creationOn: '12/09/2020',
  },
  {
    name: 'Ranjan Singh',
    role: 'Technician',
    email: 'Ranjan.Singh@gmail.com',
    mobileNumber: 919999888,
    createdBy: 'Akash Kumar Gupta',
    creationOn: '15/09/2020',
  },
  {
    name: 'Anjali Singh',
    role: 'Technician',
    email: 'Anjali.Singh@gmail.com',
    mobileNumber: 919899888,
    createdBy: 'Akash Kumar Gupta',
    creationOn: '17/09/2020',
  },
  {
    name: 'Gaurav Kumar',
    role: 'Technician',
    email: 'Gaurav.kumar@gmail.com',
    mobileNumber: 910099888,
    createdBy: 'Akash Kumar Gupta',
    creationOn: '15/09/2020',
  },
];
