import {createAction} from 'redux-api-middleware';
import {REPORT_REQUEST, REPORT_SUCCESS, REPORT_FAILURE} from './constants';
import {API_URL} from '../../constants/envVariables';

export const getReports = (
  accessToken,
  reportType,
  startDate,
  endDate,
  status,
) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetPortalReports?reportType=${reportType}&startDate=${startDate}&endDate=${endDate}&status=${status}`,
    method: 'GET',
    types: [REPORT_REQUEST, REPORT_SUCCESS, REPORT_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
