const COLORS = {
  brandColor: {
    current: '#f37435',
    new: '#fc4c02',
  },
  opacity: {
    medium: 0.5,
    dark: 0.625,
    darker: 0.75,
    darkest: 0.875,
  },
  textColor: {
    light: '#777',
    lighter: '#999',
    lightest: '#bbb',
    lightGray: '#eeeeee',
    gray: '#b4b4b4',
    darkerGray: '#969696',
    darkestGray: '#737373',
    black: '#000000',
  },
  error: {
    background: '#fc4c02',
    hover: 'rgba(218, 89, 74, 0.9)',
    text: 'rgba(218, 89, 74, 1)',
  },
  success: {
    background: '#38A169',
    hover: 'rgba(49, 160, 96, 0.9)',
    text: 'rgba(49, 160, 96, 1)',
  },
  warning: {
    background: '#78be20',
    hover: 'rgba(222, 188, 49, 0.9)',
    text: 'rgba(222, 188, 49, 1)',
  },
};

export default COLORS;
