import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ForceChangePassword from '../components/forceChangePassword/ForceChangePassword';

const ForceChangePasswordContainer = (props) => {
  return <ForceChangePassword {...props} />;
};

//#region mapStateToProps
const mapStateToProps = (state: any) => ({});

//#endregion

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForceChangePasswordContainer);
