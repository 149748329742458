import {createSelector} from 'reselect';

export const getserviceFormsClose = ({serviceFormsClose}) => serviceFormsClose;

export const getServiceFormCloseData = createSelector(
  getserviceFormsClose,
  (getserviceFormsClose) => getserviceFormsClose.dataServices?.tableData,
);
export const isServiceFormCloseFetching = createSelector(
  getserviceFormsClose,
  (getserviceFormsClose) => getserviceFormsClose.isFetchingServices,
);
export const isServiceFormForceClosed = createSelector(
  getserviceFormsClose,
  (getserviceFormsClose) => getserviceFormsClose.isClosingServiceForm,
);

export const hasForceCloseService = createSelector(
  getserviceFormsClose,
  (getserviceFormsClose) => getserviceFormsClose.hasServiceForceClosed,
);
export const isForceClosingService = createSelector(
  getserviceFormsClose,
  (getserviceFormsClose) => getserviceFormsClose.isClosingServiceForm,
);
export const getServiceFormCloseCsvData = createSelector(
  getserviceFormsClose,
  (getserviceFormsClose) =>
    getserviceFormsClose.dataServices?.failedCallCsvData,
);
