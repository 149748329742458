import React, {useState} from 'react';
import {Modal, Form, Collapse, Input, Col, Table, Row, Button} from 'antd';
import {Parser} from 'json2csv';
import {punchInPunchOutColumns} from '../../data/punchInPunchOutHistoryColumns';
import moment from 'moment';

const {Panel} = Collapse;

const ServiceEngineerModal = ({visible, onCancel, onCreate, data}) => {
  const [csvData, setCsvData] = useState([]);
  const downloadDataDumpClick = () => {
    if (data.attendenceHistory.length > 0 && data.attendenceHistory) {
      // setCsvData(
      //   data.attendenceHistory.map(function (item) {
      //     return {
      //       'Attendance Date': item.attendenceDate,
      //       'Punch In': item.punchIn,
      //       'Punch In Location': item.punchInLocation,
      //       'Punch Out': item.punchOut,
      //       'Punch Out Location': item.punchOut,
      //     };
      //   }),
      // );
      setCsvData(
        data.attendenceHistory.map(function (item) {
          return {
            'Attendance Date': item.attendenceDate
              ? moment(item.attendenceDate).format('DD/MM/YYYY hh:mm A')
              : '',
            'Punch In': item.punchIn
              ? moment(item.punchIn).format('DD/MM/YYYY hh:mm A')
              : '',
            'Punch In Location': item.punchInLocation,
            'Punch Out': item.punchOut
              ? moment(item.punchOut).format('DD/MM/YYYY hh:mm A')
              : '',
            'Punch Out Location': item.punchOutLocation,
          };
        }),
      );
      if (csvData && csvData.length > 0) {
        const headers = Object.keys(csvData[0]);

        const parser = new Parser(headers);
        const csv = parser.parse(csvData);
        var blob = new Blob([csv], {type: 'text/csv'});
        if (window.navigator.msSaveOrOpenBlob)
          window.navigator.msSaveBlob(
            blob,
            `PunchInPunchOutHistory_${new Date()}.csv`,
          );
        else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
          a.download = `ActiveServicesReport_${new Date()}.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    }
  };
  return (
    <Modal
      cancelText="Close"
      footer={null}
      onCancel={onCancel}
      title="Service Engineer  Details"
      visible={visible}
      width={1000}>
      <Form>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Customer Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Service Engineer Code</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader"> Service Engineer Name</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.empCode : ''} />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input
                      disabled
                      value={data ? data.serviceEngineerName : ''}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Service Engineer Number</td>
                  <td></td>
                  <td></td>
                  <td className="tdHeader">Service Engineer Email Address</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.phoneNumber : ''} />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Input disabled value={data ? data.emailAddress : ''} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Punch In-Out Details" key="3">
            <Row>
              <Col span={24}>
                <Button
                  onClick={() => downloadDataDumpClick()}
                  style={{
                    background: '#38a169',
                    border: '#38a169',
                    fontWeight: '550',
                    float: 'left',
                    marginRight: '10px',
                  }}
                  type="primary">
                  Export Data
                </Button>
              </Col>
            </Row>
            <div className="modalTable">
              <Table
                columns={punchInPunchOutColumns}
                dataSource={data ? data.attendenceHistory : []}
                loading={false}
                pagination={true}
                scroll={{x: 440}}
                style={{marginTop: '10px'}}
              />
            </div>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};
export default ServiceEngineerModal;
