import {createSelector} from 'reselect';

export const getserviceEngineer = ({serviceEngineerDetails}) =>
  serviceEngineerDetails;

export const getServiceEngineerData = createSelector(
  getserviceEngineer,
  (getserviceEngineer) => getserviceEngineer.dataServiceEngineer,
);
export const isServiceEngineerFetching = createSelector(
  getserviceEngineer,
  (getserviceEngineer) => getserviceEngineer.isFetchingServiceEngineer,
);
