import {
  SERVICEFORMCLOSE_REQUEST,
  SERVICEFORMCLOSE_SUCCESS,
  SERVICEFORMCLOSE_FAILURE,
  FORCECLOSEREQUEST_REQUEST,
  FORCECLOSEREQUEST_SUCCESS,
  FORCECLOSEREQUEST_FAILURE,
} from './constants';
import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';

export const getServiceFormClose = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetServiceFormCloseData`,
    method: 'GET',
    types: [
      SERVICEFORMCLOSE_REQUEST,
      SERVICEFORMCLOSE_SUCCESS,
      SERVICEFORMCLOSE_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const forceCloseService = (serviceDetailsId, accessToken) => {
  return createAction({
    endpoint: `${API_URL}/Service/ForceCloseServiceRequestFromPortal`,
    method: 'POST',
    body: JSON.stringify({serviceDetailsId}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*', // Required for CORS support to work
      'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
    },
    types: [
      FORCECLOSEREQUEST_REQUEST,
      {
        type: FORCECLOSEREQUEST_SUCCESS,
        payload: {serviceDetailsId},
      },
      FORCECLOSEREQUEST_FAILURE,
    ],
  });
};
