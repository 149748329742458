import {
  SERVICEENGINEER_REQUEST,
  SERVICEENGINEER_FAILURE,
  SERVICEENGINEER_SUCCESS,
} from './constant';

import {combineReducers} from 'redux';

const isFetchingServiceEngineer = (state = false, action) => {
  switch (action.type) {
    case SERVICEENGINEER_REQUEST:
      return true;
    case SERVICEENGINEER_SUCCESS:
    case SERVICEENGINEER_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataServiceEngineer = (state = null, action) => {
  switch (action.type) {
    case SERVICEENGINEER_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

const failureServiceEngineer = (state = null, action) => {
  switch (action.type) {
    case SERVICEENGINEER_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasServiceEngineerFetched = (state = false, action) => {
  switch (action.type) {
    case SERVICEENGINEER_REQUEST:
      return false;
    case SERVICEENGINEER_SUCCESS:
    case SERVICEENGINEER_FAILURE:
      return true;
    default:
      return state;
  }
};
export default combineReducers({
  isFetchingServiceEngineer,
  dataServiceEngineer,
  failureServiceEngineer,
  hasServiceEngineerFetched,
});
