import React from 'react';
import moment from 'moment';

export const punchInPunchOutColumns = [
  {
    title: 'Date',
    dataIndex: 'attendenceDate',
    key: 'attendenceDate',
    render: (attendenceDate) => {
      return attendenceDate ? (
        <p>{moment(attendenceDate).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.attendenceDate) / moment(b.attendenceDate),
    },
  },
  {
    title: 'Punch In',
    dataIndex: 'punchIn',
    key: 'empCode',
    render: (punchIn) => {
      return punchIn ? (
        <p>{moment(punchIn).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.punchIn) / moment(b.punchIn),
    },
  },
  {
    title: 'Punch In Location',
    dataIndex: 'punchInLocation',
    key: 'attendanceDate',
    sorter: {
      compare: (a, b) => a.servicesCount / b.servicesCount,
    },
  },
  {
    title: 'Punch Out',
    dataIndex: 'punchOut',
    key: 'punchOut',
    render: (punchOut) => {
      return punchOut ? (
        <p>{moment(punchOut).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.punchOut) / moment(b.punchOut),
    },
  },
  {
    title: 'Punch Out Location',
    dataIndex: 'punchOutLocation',
    key: 'punchOutLocation',
    sorter: {
      compare: (a, b) => a.lastStatus / b.lastStatus,
    },
  },
];
