export const userRoles = [
  {
    key: 'All',
    value: 'All',
  },
  {
    key: 'Admin',
    value: 'Admin',
  },
  {
    key: 'Dealer',
    value: 'Dealer',
  },
  {
    key: 'Dealer Technician',
    value: 'Dealer Technician',
  },
  {
    key: 'THCM Employee',
    value: 'THCM Employee',
  },
  {
    key: 'THCM Technician',
    value: 'THCM Technician',
  },
];
