import React, {useState, useEffect} from 'react';
import {Parser} from 'json2csv';
import MainLayout from '../../layouts/MainLayout';
import DataFilterBox from '../common/DataFilterBox';
import moment from 'moment';
import {Alert} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getReports} from '../../modules/Reports/action';
import {
  getIsFetchingReports,
  getReportData,
  getIsFetchingReportsError,
  getReportsError,
} from '../../modules/Reports/selector';

export const Report = () => {
  const [reportType, setReportType] = useState('');
  const [report, setReport] = useState('');
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const reportData = useSelector(getReportData);
  const isReportFetching = useSelector(getIsFetchingReports);
  const isReportFetchingError = useSelector(getIsFetchingReportsError);
  const reportError = useSelector(getReportsError);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (reportData) {
      if (!isReportFetchingError && !isReportFetching && report) {
        if (reportData[report].length > 0) {
          const headers = Object.keys(reportData[report][0]);
          const parser = new Parser(headers);
          const csv = parser.parse(reportData[report]);
          var blob = new Blob([csv], {type: 'text/csv'});
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(
              blob,
              `Report_${reportType}_${new Date()}.csv`,
            );
          } else {
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
            a.download = `ReportData_${reportType}_${new Date()}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        } else if (reportData[report].length === 0) {
          // alert('No Reports Found for Selected Period Range');
          setErrorText('No Reports Found for Selected Period Range');
          setIsError(true);
        }
      } else if (isReportFetchingError) {
        // alert(`Error:${reportError}`);
        setErrorText(`Error:${reportError}`);
        setIsError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportFetchingError, reportData, reportError]);

  const [fromServiceReportDate, setServiceReportFromDate] = useState(
    moment(moment(new Date()).add(-7, 'day').toDate()),
  );
  const [toServiceReportDate, setServiceReportToDate] = useState(
    moment(new Date()),
  );
  const fromServiceReportDateChanged = (fromDate) => {
    setServiceReportFromDate(fromDate);
  };
  const toServiceReportDateChanged = (toDate) => {
    setServiceReportToDate(toDate);
  };
  const onFilterServicesActiveDataClick = () => {
    if (fromServiceReportDate && toServiceReportDate) {
      setReportType('Active');
      setReport('servicesReports');
      dispatch(
        getReports(
          authUser.accessToken,
          'servicesReport',
          fromServiceReportDate?.format('YYYY-MM-DD'),
          toServiceReportDate?.format('YYYY-MM-DD'),
          'Active',
        ),
      );
    }
  };
  const onFilterServiceStartedDataClick = () => {
    setReportType('ServiceStarted');
    setReport('servicesReports');
    dispatch(
      getReports(
        authUser.accessToken,
        'servicesReport',
        fromServiceReportDate?.format('YYYY-MM-DD'),
        toServiceReportDate?.format('YYYY-MM-DD'),
        'Service%20Started',
      ),
    );
  };
  const onFilterServicesCompletedDataClick = () => {
    setReportType('Completed');
    setReport('servicesReports');
    dispatch(
      getReports(
        authUser.accessToken,
        'servicesReport',
        fromServiceReportDate?.format('YYYY-MM-DD'),
        toServiceReportDate?.format('YYYY-MM-DD'),
        'Completed',
      ),
    );
  };
  const onFilterServicesAllDataClick = () => {
    setReportType('All');
    setReport('servicesReports');
    dispatch(
      getReports(
        authUser.accessToken,
        'servicesReport',
        fromServiceReportDate?.format('YYYY-MM-DD'),
        toServiceReportDate?.format('YYYY-MM-DD'),
        'All',
      ),
    );
  };

  const [fromTripReportDate, setTripReportFromDate] = useState(
    moment(moment(new Date()).add(-7, 'day').toDate()),
  );
  const [toTripReportDate, setTripReportToDate] = useState(moment(new Date()));
  const fromTripReportDateChanged = (fromDate) => {
    setTripReportFromDate(fromDate);
  };
  const toTripReportDateChanged = (toDate) => {
    setTripReportToDate(toDate);
  };
  const onTripFilterDataClick = () => {
    setReportType('Trip');
    setReport('tripDetailsReports');
    dispatch(
      getReports(
        authUser.accessToken,
        'tripDetailsReport',
        fromTripReportDate?.format('YYYY-MM-DD'),
        toTripReportDate?.format('YYYY-MM-DD'),
        '',
      ),
    );
  };

  const [fromPunchingReportDate, setPunchingReportFromDate] = useState(
    moment(moment(new Date()).add(-7, 'day').toDate()),
  );
  const [toPunchingReportDate, setPunchingReportToDate] = useState(
    moment(new Date()),
  );
  const fromPunchingReportDateChanged = (fromDate) => {
    setPunchingReportFromDate(fromDate);
  };
  const toPunchingReportDateChanged = (toDate) => {
    setPunchingReportToDate(toDate);
  };
  const onPunchingFilterDataClick = () => {
    setReportType('Punching');
    setReport('punchingDetailsReports');
    dispatch(
      getReports(
        authUser.accessToken,
        'punchingDetailsReport',
        fromPunchingReportDate?.format('YYYY-MM-DD'),
        toPunchingReportDate?.format('YYYY-MM-DD'),
        '',
      ),
    );
  };
  // const [
  //   fromPartRecommendationReportDate,
  //   setPartRecommendationReportFromDate,
  // ] = useState(moment(new Date()).add(-7, 'day').toDate());
  // const [
  //   toPartRecommendationReportDate,
  //   setPartRecommendationReportToDate,
  // ] = useState(moment(new Date()));
  // const fromPartRecommendationReportDateChanged = () => {};
  // const toPartRecommendationReportDateChanged = () => {};
  // const onPartRecommendationFilterDataClick = () => {};
  const serviceReportItems = [
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromServiceReportDateChanged,
      value: fromServiceReportDate,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toServiceReportDateChanged,
      value: toServiceReportDate,
    },
    {
      fieldId: 4,
      fieldName: 'Active',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterServicesActiveDataClick,
    },
    {
      fieldId: 5,
      fieldName: 'Service Started',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterServiceStartedDataClick,
    },
    {
      fieldId: 6,
      fieldName: 'Completed',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterServicesCompletedDataClick,
    },
    {
      fieldId: 7,
      fieldName: 'All',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterServicesAllDataClick,
    },
  ];
  const tripDetailsReportItems = [
    {
      fieldId: 7,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromTripReportDateChanged,
      value: fromTripReportDate,
    },
    {
      fieldId: 8,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toTripReportDateChanged,
      value: toTripReportDate,
    },
    {
      fieldId: 9,
      fieldName: 'Download Report',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onTripFilterDataClick,
    },
  ];
  const punchingeReportItems = [
    {
      fieldId: 13,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromPunchingReportDateChanged,
      value: fromPunchingReportDate,
    },
    {
      fieldId: 14,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toPunchingReportDateChanged,
      value: toPunchingReportDate,
    },
    {
      fieldId: 15,
      fieldName: 'Download Report',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onPunchingFilterDataClick,
    },
  ];

  // const partRecommendationReportItems = [
  //   {
  //     fieldId: 10,
  //     fieldName: '',
  //     fieldTitle: 'From Date',
  //     fieldType: 'DatePicker',
  //     onChange: fromPartRecommendationReportDateChanged,
  //     value: fromPartRecommendationReportDate,
  //   },
  //   {
  //     fieldId: 11,
  //     fieldName: '',
  //     fieldTitle: 'To Date',
  //     fieldType: 'DatePicker',
  //     onChange: toPartRecommendationReportDateChanged,
  //     value: toPartRecommendationReportDate,
  //   },
  //   {
  //     fieldId: 12,
  //     fieldName: 'Download Report',
  //     fieldTitle: '',
  //     fieldType: 'Button',
  //     onClick: onPartRecommendationFilterDataClick,
  //   },
  // ];

  return (
    <MainLayout>
      <div className="contentLayout">
        {isReportFetching ? (
          <div>
            <LoadingOutlined />
          </div>
        ) : isError ? (
          <Alert
            closable
            message={errorText}
            onClose={() => {
              setErrorText('');
              setIsError(false);
            }}
            type="error"
          />
        ) : null}
        <DataFilterBox items={serviceReportItems} title="Services Report" />
        <DataFilterBox
          items={tripDetailsReportItems}
          title="Trip Details Report"
        />
        <DataFilterBox
          items={punchingeReportItems}
          title="Punching Details Report"
        />
        {/* <DataFilterBox
          items={partRecommendationReportItems}
          title="Part Recommendations Report"
        /> */}
      </div>
    </MainLayout>
  );
};
