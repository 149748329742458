import React, {useEffect, useState} from 'react';
import {Form, message, Input, Button} from 'antd';
import {UserOutlined, LockOutlined, LoginOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {signIn} from '../../modules/auth/action';
import {
  getAuthIsPasswordChangeRequired,
  loginErrorDetails,
  isLoginFailed,
} from '../../modules/auth/selectors';
import {checkPortalAccess} from '../../modules/userDetails/action';
import {
  checkPortalAccessValue,
  checkingPortalAccess,
} from '../../modules/userDetails/selector';
import {NavLink, Redirect} from 'react-router-dom';
import {FORCECHANGEPASSWORD} from '../routes';
import {getisChangedPasswordSuccess} from '../../modules/auth/forceChangePassword/selector';

const Login = (props) => {
  const dispatch = useDispatch();
  const isPasswordChangeSuccess = useSelector(getisChangedPasswordSuccess);
  const [isRedirected, setIsRedirected] = useState(false);
  const isPasswordChangeRequired = useSelector(getAuthIsPasswordChangeRequired);
  const getErrorDetails = useSelector(loginErrorDetails);
  const loginFailed = useSelector(isLoginFailed);

  const portalAccessData = useSelector(checkPortalAccessValue);
  const checkingAccess = useSelector(checkingPortalAccess);
  const [credentials, setCredentials] = useState();
  const [isSignInClicked, setIsSignInClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPasswordChangeRequired && !isPasswordChangeSuccess) {
      setIsRedirected(true);
    }
  }, [isPasswordChangeRequired, isPasswordChangeSuccess]);

  useEffect(() => {
    if (loginFailed) {
      message.error({
        content: getErrorDetails.err.message,
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      setLoading(false);
    }
  }, [loginFailed, getErrorDetails]);

  const signInClicked = (values) => {
    dispatch(checkPortalAccess(values.username));
    setCredentials(values);
    setIsSignInClicked(true);
    setLoading(true);
  };

  useEffect(() => {
    if (isSignInClicked) {
      console.log('entered');
      if (portalAccessData && !checkingAccess) {
        dispatch(signIn(credentials));
        setIsSignInClicked(false);
        setLoading(false);
      } else if (!portalAccessData && !checkingAccess) {
        message.info({
          content:
            'You dont have access to the portal ,please contact the adminstrator!!',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        });
        setIsSignInClicked(false);
        setLoading(false);
      }
    }
  }, [
    portalAccessData,
    dispatch,
    credentials,
    isSignInClicked,
    checkingAccess,
  ]);

  return isRedirected ? (
    <Redirect to={{pathname: FORCECHANGEPASSWORD.path}} />
  ) : (
    <div className="divContainer">
      <span
        className="contentHeader"
        style={{
          marginBottom: '10px',
          display: 'block',
          textAlign: 'center',
          color: '#FFFFFF',
        }}>
        TATA HITACHI Service
      </span>

      <Form
        className="login-form"
        initialValues={{
          remember: true,
        }}
        name="normal_login"
        onFinish={(values) => dispatch(signInClicked(values))}
        style={{marginTop: '20px'}}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}>
          <Input
            placeholder="Username"
            prefix={
              <UserOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}>
          <Input
            placeholder="Password"
            prefix={
              <LockOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
            type="password"
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'block',
          }}>
          <Button
            className="login-form-button"
            htmlType="submit"
            style={{
              background: '#fd560eb5',
              border: '#FFFFFF',
              fontWeight: '550',
            }}
            type="primary">
            <LoginOutlined /> {loading ? 'Processing...' : 'Log in'}
          </Button>

          <NavLink
            activeClassName="anchor"
            style={{
              float: 'right',
              fontWeight: '550',
              fontSize: '14px',
              fontFamily: 'Arial',
              color: '#fd560eb5',
            }}
            to="/forgotPasswordVerificationCode">
            Forgot password ?
          </NavLink>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
