import React, {useEffect, useState} from 'react';
import {Form, message, Input, Button} from 'antd';
import {UserOutlined, SendOutlined} from '@ant-design/icons';
import {forgotPasswordSendCode} from '../../modules/auth/forgotPassword/forgotPasswordVerificationCode/action';
import {useDispatch, useSelector} from 'react-redux';
import {getIsForgotPasswordSendCodeSuccess} from '../../modules/auth/forgotPassword/forgotPasswordVerificationCode/selector';
import {NavLink, Redirect} from 'react-router-dom';
import {FORGOTPASSWORD} from '../routes';

export const ForgotPasswordVerificationCode = () => {
  const dispatch = useDispatch();
  const isVerificationCodeSent = useSelector(
    getIsForgotPasswordSendCodeSuccess,
  );
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    if (isVerificationCodeSent) {
      message.success({
        content: 'Verification code sent successfully !!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      setIsRedirected(true);
    }
  }, [isVerificationCodeSent]);

  return isRedirected ? (
    <Redirect to={{pathname: FORGOTPASSWORD.path}} />
  ) : (
    <div className="divContainer" style={{}}>
      <span
        className="contentHeader"
        style={{
          marginBottom: '10px',
          display: 'block',
          textAlign: 'center',
          color: '#FFFFFF',
        }}>
        TATA HITACHI Sales
      </span>
      <span
        style={{
          margin: '10px',
          display: 'block',
          textAlign: 'center',
          color: '#797878',
          fontWeight: 550,
          fontSize: '15px',
        }}>
        Please enter the username to receive the code over the email.
      </span>
      <Form
        className="login-form"
        initialValues={{
          remember: true,
        }}
        name="normal_login"
        onFinish={(values) => dispatch(forgotPasswordSendCode(values))}
        style={{marginTop: '20px'}}>
        <Form.Item
          name="Username"
          rules={[
            {
              required: true,
              message: 'Please input your username',
            },
          ]}>
          <Input
            placeholder="Username"
            prefix={
              <UserOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'block',
          }}>
          <Button
            className="login-form-button"
            htmlType="submit"
            style={{
              background: '#fd560eb5',
              border: '#FFFFFF',
              fontWeight: '550',
            }}
            type="primary">
            <SendOutlined /> Send Code
          </Button>
          <NavLink
            activeClassName="anchor"
            style={{
              float: 'right',
              fontWeight: '550',
              fontSize: '14px',
              fontFamily: 'Arial',
              color: '#fd560eb5',
            }}
            to="/login">
            Log In
          </NavLink>
        </Form.Item>
      </Form>
    </div>
  );
};
