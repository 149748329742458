import {
  SERVICEENGINEER_REQUEST,
  SERVICEENGINEER_SUCCESS,
  SERVICEENGINEER_FAILURE,
} from './constant';
import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';

export const getserviceEngineer = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetServiceEngineerDetails`,
    method: 'GET',
    types: [
      SERVICEENGINEER_REQUEST,
      SERVICEENGINEER_SUCCESS,
      SERVICEENGINEER_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
