import React, {useEffect, useState} from 'react';
import {Form, message, Input, Button} from 'antd';
import {
  SendOutlined,
  KeyOutlined,
  UserOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import {getIsForgotPasswordSendCodeSuccess} from '../../modules/auth/forgotPassword/forgotPasswordVerificationCode/selector';
import {Redirect} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {LOGIN} from '../routes';
import {getIsForgotPasswordChangedSuccess} from '../../modules/auth/forgotPassword/selector';
import {
  forgotPasswordChange,
  clearStore,
} from '../../modules/auth/forgotPassword/action';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const isVerificationCodeSent = useSelector(
    getIsForgotPasswordSendCodeSuccess,
  );
  const isForgotPasswordChanged = useSelector(
    getIsForgotPasswordChangedSuccess,
  );

  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    if (isForgotPasswordChanged) {
      dispatch(clearStore());
      message.success({
        content: 'Password changed sucessfully please login!!',
        className: 'custom-class',
        top: 100,
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      setIsRedirected(true);
    }
  }, [isForgotPasswordChanged, dispatch]);

  useEffect(() => {
    if (!isVerificationCodeSent) setIsRedirected(true);
  }, [isVerificationCodeSent]);

  const updatePassword = ({username, code, newPassword, confirmPassword}) => {
    if (newPassword !== confirmPassword) {
      message.error({
        content: 'New password and Confirm password does not match !!',
        className: 'custom-class',
        top: 100,
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      return;
    }
    dispatch(forgotPasswordChange(username, code, newPassword));
  };

  return isRedirected ? (
    <Redirect to={{pathname: LOGIN.path}} />
  ) : (
    <div className="divContainer" style={{minHeight: '52vh'}}>
      <span
        className="contentHeader"
        style={{
          marginBottom: '5px',
          display: 'block',
          textAlign: 'center',
          color: '#FFFFFF',
        }}>
        TATA HITACHI Sales
      </span>
      <span
        style={{
          margin: '5px',
          display: 'block',
          textAlign: 'center',
          color: '#797878',
          fontWeight: 550,
          fontSize: '15px',
        }}>
        Please enter the verification code and new password.
      </span>
      <Form
        className="login-form"
        initialValues={{
          remember: true,
        }}
        name="normal_login"
        onFinish={(values) => updatePassword(values)}
        style={{marginTop: '15px'}}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}>
          <Input
            placeholder="Username"
            prefix={
              <UserOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
          />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: 'Please input your Verification Code!',
            },
          ]}>
          <Input
            placeholder="Verification Code"
            prefix={
              <KeyOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please input your New Password!',
            },
          ]}>
          <Input
            placeholder="New Password"
            prefix={
              <UnlockOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Please input your Confirm Password!',
            },
          ]}>
          <Input
            placeholder="Confirm Password"
            prefix={
              <UnlockOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
            type="password"
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'block',
          }}>
          <Button
            className="login-form-button"
            htmlType="submit"
            style={{
              background: '#fd560eb5',
              border: '#FFFFFF',
              fontWeight: '550',
            }}
            type="primary">
            <SendOutlined /> Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
