import {combineReducers} from 'redux';
import app from './app/reducer';
import auth from './auth/reducer';
import userInfo from './userDetails/reducer';
import forceChangePassword from './auth/forceChangePassword/reducer';
import forgotPasswordVerificationCode from './auth/forgotPassword/forgotPasswordVerificationCode/reducer';
import forgotPasswordChanged from './auth/forgotPassword/reducer';
import dashboard from './dashboard/reducer';
import serviceForms from './serviceForms/reducer';
import serviceFormsClose from './serviceFormClose/reducer';
import serviceEngineerDetails from './serviceEngineer/reducer';
import Reports from './Reports/reducer';
import territoryFilter from './territoryFilter/reducer';

const appReducer = combineReducers({
  serviceEngineerDetails,
  app,
  auth,
  userInfo,
  forceChangePassword,
  forgotPasswordVerificationCode,
  forgotPasswordChanged,
  dashboard,
  serviceFormsClose,
  serviceForms,
  Reports,
  territoryFilter,
});

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action);
  return appReducer(state, action);
};

// export const rootReducer = combineReducers({
//   app,
//   auth,
//   accounts,
//   contacts,
//   userInfo,
//   forceChangePassword,
//   forgotPasswordVerificationCode,
//   forgotPasswordChanged,
//   opportunities,
// });
