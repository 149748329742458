import {
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARDSTATUS_REQUEST,
  DASHBOARDSTATUS_SUCCESS,
  DASHBOARDSTATUS_FAILURE,
} from './constants';
import {API_URL} from '../../constants/envVariables';
import {createAction} from 'redux-api-middleware';

export const getDashboard = (
  accessToken,
  status,
  pageNumber,
  pageSize,
  followUpCallId,
) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetPortalService?Status=${status}&PageNumber=${pageNumber}&PageSize=${pageSize}&FollowUpCallId=${followUpCallId}`,
    method: 'GET',
    types: [DASHBOARD_REQUEST, DASHBOARD_SUCCESS, DASHBOARD_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getDashboardCount = (accessToken, date) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetServiceCountOnStatus?pastNoOfDays=${date}`,
    method: 'GET',
    types: [
      DASHBOARDSTATUS_REQUEST,
      DASHBOARDSTATUS_SUCCESS,
      DASHBOARDSTATUS_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
