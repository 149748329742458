import {combineReducers} from 'redux';
import {
  FORGOT_PASSWORD_CHANGE_SUCCESS,
  FORGOT_PASSWORD_CHANGE_FAILURE,
} from './constants';

const isForgotPasswordChangedSuccess = (state = false, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_CHANGE_SUCCESS:
      return true;
    case FORGOT_PASSWORD_CHANGE_FAILURE:
      return false;
    default:
      return state;
  }
};

const isForgotPasswordChangedError = (state = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_CHANGE_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export default combineReducers({
  isForgotPasswordChangedSuccess,
  isForgotPasswordChangedError,
});
