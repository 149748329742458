import {
  SERVICEFORM_REQUEST,
  SERVICEFORM_SUCCESS,
  SERVICEFORM_FAILURE,
  FORMSPECIFICDATA_FAILURE,
  FORMSPECIFICDATA_REQUEST,
  FORMSPECIFICDATA_SUCCESS,
  SAVEFIRFORM_FAILURE,
  SAVEFIRFORM_REQUEST,
  SAVEFIRFORM_SUCCESS,
  RESET_DEFAULT,
  DOWNLOADING_PDF,
  SAVE_PDF,
  ERROR_PDF_DOWNLOAD,
  END_TRIP_DATE_REQUEST,
  END_TRIP_DATE_SUCCESS,
  END_TRIP_DATE_FAILURE,
  MALFUNCTION_DATE_REQUEST,
  MALFUNCTION_DATE_SUCCESS,
  MALFUNCTION_DATE_FAILURE,
} from './constants';

import {combineReducers} from 'redux';

const isFetchingServices = (state = false, action) => {
  switch (action.type) {
    case SERVICEFORM_REQUEST:
      return true;
    case SERVICEFORM_SUCCESS:
    case SERVICEFORM_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataServices = (state = null, action) => {
  switch (action.type) {
    case SERVICEFORM_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureServices = (state = null, action) => {
  switch (action.type) {
    case SERVICEFORM_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasServiceFetched = (state = false, action) => {
  switch (action.type) {
    case SERVICEFORM_REQUEST:
      return false;
    case SERVICEFORM_SUCCESS:
    case SERVICEFORM_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingFormSpecificData = (state = false, action) => {
  switch (action.type) {
    case FORMSPECIFICDATA_REQUEST:
      return true;
    case FORMSPECIFICDATA_SUCCESS:
    case FORMSPECIFICDATA_FAILURE:
      return false;
    default:
      return state;
  }
};

const formSpecificData = (state = null, action) => {
  switch (action.type) {
    case FORMSPECIFICDATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureFormSpecificData = (state = null, action) => {
  switch (action.type) {
    case FORMSPECIFICDATA_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFormSpecificDataFetched = (state = false, action) => {
  switch (action.type) {
    case FORMSPECIFICDATA_REQUEST:
      return false;
    case FORMSPECIFICDATA_SUCCESS:
    case FORMSPECIFICDATA_FAILURE:
      return true;
    default:
      return state;
  }
};
const isSavingFIRForm = (state = false, action) => {
  switch (action.type) {
    case SAVEFIRFORM_REQUEST:
      return true;
    case SAVEFIRFORM_SUCCESS:
    case SAVEFIRFORM_FAILURE:
      return false;
    case RESET_DEFAULT:
      return false;
    default:
      return state;
  }
};

const firFormData = (state = null, action) => {
  switch (action.type) {
    case SAVEFIRFORM_SUCCESS:
      return action;
    default:
      return state;
  }
};

const failureFIRFormSave = (state = false, action) => {
  switch (action.type) {
    case SAVEFIRFORM_FAILURE:
      return true;
    case RESET_DEFAULT:
      return false;
    default:
      return state;
  }
};

const hasSavedFIRForm = (state = false, action) => {
  switch (action.type) {
    case SAVEFIRFORM_SUCCESS:
      return true;
    case RESET_DEFAULT:
      return false;
    default:
      return state;
  }
};

const downloadingPDF = (state = false, action) => {
  switch (action.type) {
    case DOWNLOADING_PDF:
      return true;
    case SAVE_PDF:
    case ERROR_PDF_DOWNLOAD:
      return false;
    default:
      return state;
  }
};
const downloadedPDF = (state = false, action) => {
  switch (action.type) {
    case SAVE_PDF:
      return action.payload;
    case ERROR_PDF_DOWNLOAD:
    default:
      return state;
  }
};
const errorDownloadingPDF = (state = false, action) => {
  switch (action.type) {
    case ERROR_PDF_DOWNLOAD:
      return true;
    case SAVE_PDF:
    default:
      return state;
  }
};

const endTripDate = (state = null, action) => {
  switch (action.type) {
    case END_TRIP_DATE_REQUEST:
    case END_TRIP_DATE_FAILURE:
      return null;
    case END_TRIP_DATE_SUCCESS:
      return action.payload.endTripDate;
    default:
      return state;
  }
};

const failureDate = (state = null, action) => {
  switch (action.type) {
    case MALFUNCTION_DATE_REQUEST:
    case MALFUNCTION_DATE_FAILURE:
      return null;
    case MALFUNCTION_DATE_SUCCESS:
      return action.payload.failureDate;
    default:
      return state;
  }
};
export default combineReducers({
  isFetchingServices,
  dataServices,
  failureServices,
  hasServiceFetched,
  isFetchingFormSpecificData,
  formSpecificData,
  failureFormSpecificData,
  hasFormSpecificDataFetched,
  isSavingFIRForm,
  hasSavedFIRForm,
  firFormData,
  failureFIRFormSave,
  errorDownloadingPDF,
  downloadedPDF,
  downloadingPDF,
  endTripDate,
  failureDate,
});
