import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getApp} from '../modules/app/selectors';
import {getAuthIsAuthenticated} from '../modules/auth/selectors';
import App from '../components/app/App';

const AppContainer = (props) => {
  return <App {...props} />;
};

//#region mapStateToProps
const mapStateToProps = (state: any) => ({
  app: getApp(state),
  isAuthenticated: getAuthIsAuthenticated(state),
});

//#endregion

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
