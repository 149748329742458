import {createSelector} from 'reselect';

export const getDashboard = ({dashboard}) => dashboard;

export const getDashboardData = createSelector(
  getDashboard,
  (getDashboard) => getDashboard.dataDashboard?.tableData,
);
// export const getDashboardCsvData = createSelector(
//   getDashboard,
//   (getDashboard) => getDashboard.dataDashboard?.csvDatas,
// );
export const getDashboardTableDataCount = createSelector(
  getDashboard,
  (getDashboard) => getDashboard.dataDashboard?.dataCount,
);
export const isDashboardDataFetched = createSelector(
  getDashboard,
  (getDashboard) => getDashboard.hasDashboardFetched,
);
export const getDashboardDataCount = createSelector(
  getDashboard,
  (getDashboard) => getDashboard.dataDashboardCount,
);
export const isDashboardDataCountFetched = createSelector(
  getDashboard,
  (getDashboard) => getDashboard.hasDashboardCountFetched,
);
