import React, {useEffect, useState} from 'react';
import {Form, message, Input, Button} from 'antd';
import {UnlockOutlined, LoginOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {forceChangePassword} from '../../modules/auth/forceChangePassword/action';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {Redirect} from 'react-router-dom';
import {LOGIN} from '../routes';
import {getAuthIsPasswordChangeRequired} from '../../modules/auth/selectors';
import {getisChangedPasswordSuccess} from '../../modules/auth/forceChangePassword/selector';

const ForceChangePassword = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const [isRedirected, setIsRedirected] = useState(false);
  const isPasswordChangeRequired = useSelector(getAuthIsPasswordChangeRequired);
  const isPasswordChangeSuccess = useSelector(getisChangedPasswordSuccess);

  useEffect(() => {
    if (isPasswordChangeSuccess) {
      message.success({
        content: 'Password changed sucessfully. Please login again !!',
        className: 'custom-class',
        top: 100,
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      setIsRedirected(true);
      localStorage.clear();
    }
  }, [isPasswordChangeSuccess]);

  useEffect(() => {
    if (!isPasswordChangeRequired) {
      setIsRedirected(true);
    }
  }, [isPasswordChangeRequired]);

  let validationMessage;
  const ConfirmChangePassword = ({newPassword, confirmNewPassword}) => {
    if (newPassword !== confirmNewPassword) {
      message.error({
        content: 'New password and Confirm password does not match !!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });

      return;
    }
    dispatch(forceChangePassword(authUser.cognitoUser, newPassword));
  };

  return isRedirected ? (
    <Redirect to={{pathname: LOGIN.path}} />
  ) : (
    <div className="divContainer" style={{}}>
      {validationMessage}
      <span
        className="contentHeader"
        style={{
          marginBottom: '10px',
          display: 'block',
          textAlign: 'center',
          color: '#FFFFFF',
        }}>
        TATA HITACHI Sales
      </span>
      <span
        style={{
          margin: '10px',
          display: 'block',
          textAlign: 'center',
          color: '#797878',
          fontWeight: 550,
          fontSize: '15px',
        }}>
        Please change your temporary password
      </span>
      <Form
        className="login-form"
        initialValues={{
          remember: true,
        }}
        name="normal_login"
        onFinish={(values) => ConfirmChangePassword(values)}
        style={{marginTop: '20px'}}>
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please input your New Password!',
            },
          ]}>
          <Input
            placeholder="New Password"
            prefix={
              <UnlockOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          rules={[
            {
              required: true,
              message: 'Please input your Confirm Password!',
            },
          ]}>
          <Input
            placeholder="Confirm New Password"
            prefix={
              <UnlockOutlined
                className="site-form-item-icon"
                style={{
                  color: '#797878',
                }}
              />
            }
            style={{
              borderRadius: '4px',
            }}
            type="password"
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'block',
          }}>
          <Button
            className="login-form-button"
            htmlType="submit"
            style={{
              background: '#fd560eb5',
              border: '#FFFFFF',
              fontWeight: '550',
            }}
            type="primary">
            <LoginOutlined /> Change Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForceChangePassword;
