import Amplify, {Auth} from 'aws-amplify';
import {FORGOT_PASSWORD_CHANGE_SUCCESS} from './constants';

export const forgotPasswordChange = (userName, code, newPassword) => {
  return (dispatch) => {
    return Auth.forgotPasswordSubmit(userName, code, newPassword)
      .then((data) => {
        dispatch({type: FORGOT_PASSWORD_CHANGE_SUCCESS});
      })
      .catch((err) => console.log(err));
  };
};
export const clearStore = () => {
  return (dispatch) => dispatch({type: 'CLEAR_STORE'});
};
