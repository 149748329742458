import React, {useState, useEffect} from 'react';
import {Layout, Menu} from 'antd';
import {
  BarChartOutlined,
  CloudOutlined,
  // SettingOutlined,
  // TeamOutlined,
  ContactsOutlined,
  // NotificationOutlined,
  HomeOutlined,
  UserSwitchOutlined,
  VerticalAlignBottomOutlined,
  // ToolOutlined,
  // WechatOutlined,
} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getSelfDetails} from '../modules/userDetails/selector';
import Header from './Header';

const {Content, Footer, Sider} = Layout;

const menuItems = [
  {
    name: 'Dashboard',
    exact: true,
    key: 1,
    icon: <HomeOutlined style={{fontWeight: '550'}} />,
    path: '/',
  },
  {
    name: 'User Management',
    key: 3,
    icon: <ContactsOutlined style={{fontWeight: '550'}} />,
    path: '/userManagement',
  },
  {
    name: 'Completed Calls',
    key: 4,
    icon: <BarChartOutlined style={{fontWeight: '550'}} />,
    path: '/serviceForms',
  },
  {
    name: 'Failed Calls',
    key: 5,
    icon: <CloudOutlined style={{fontWeight: '550'}} />,
    path: '/serviceCallClose',
  },
  {
    name: 'Reports',
    key: 7,
    icon: <VerticalAlignBottomOutlined style={{fontWeight: '550'}} />,
    path: '/reports',
  },
  // {
  //   name: 'Notifications',
  //   key: 7,
  //   icon: <NotificationOutlined style={{fontWeight: '550'}} />,
  //   path: '/notifications',
  // },
  // {
  //   name: 'E-Tools',
  //   key: 8,
  //   icon: <ToolOutlined style={{fontWeight: '550'}} />,
  //   path: '/eTools',
  // },
  {
    name: 'Service Engineer',
    key: 6,
    icon: <UserSwitchOutlined style={{fontWeight: '550'}} />,
    path: '/serviceEngineer',
  },
  // {
  //   name: 'Expert Chat',
  //   key: 10,
  //   icon: <WechatOutlined style={{fontWeight: '550'}} />,
  //   path: '/expertChats',
  // },
];

const MainLayout = (props) => {
  const location = useLocation();
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const selfUserinfo = useSelector(getSelfDetails);
  useEffect(() => {
    setIsUserAdmin(
      selfUserinfo
        ? selfUserinfo.roles.length > 0
          ? selfUserinfo.roles.includes('Admin')
          : false
        : false,
    );
  }, [selfUserinfo]);
  return (
    <div>
      <Layout className="site-layout" style={{minHeight: '100vh'}}>
        <Header />
        <Layout
          className="site-layout"
          style={{marginLeft: 200, marginTop: '60px'}}>
          <Sider
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
              backgroundColor: '#5d5d5d',
            }}>
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              style={{backgroundColor: '#5d5d5d', fontWeight: '550'}}
              theme="dark">
              {isUserAdmin
                ? menuItems
                    .filter((item) => item.key !== 6)
                    .map((menu) => (
                      <Menu.Item icon={menu.icon} key={menu.path}>
                        <Link to={menu.path}>{menu.name}</Link>
                      </Menu.Item>
                    ))
                : menuItems
                    .filter((item) => item.key !== 3 && item.key !== 5)
                    .map((menu) => (
                      <Menu.Item icon={menu.icon} key={menu.path}>
                        <Link to={menu.path}>{menu.name}</Link>
                      </Menu.Item>
                    ))}
            </Menu>
          </Sider>
          <Content>{props.children}</Content>
        </Layout>
        <Footer style={{textAlign: 'right'}}>
          <div>Tata Hitachi Construction Machinery Company Private Limited</div>
        </Footer>
      </Layout>
    </div>
  );
};

export default MainLayout;
