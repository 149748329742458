import {
  LOGIN_SUCCESS,
  UPDATE_USER,
  UPDATE_SESSSION,
  FORCE_CHANGE_PASSWORD,
} from '../constants';
import {combineReducers} from 'redux';

const data = (state = null, action) => {
  switch (action.type) {
    case UPDATE_USER:
    case LOGIN_SUCCESS:
    case UPDATE_SESSSION:
    case FORCE_CHANGE_PASSWORD:
      return action.user;
    default:
      return state;
  }
};
const hasFetched = (state = false, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  hasFetched,
  data,
});
