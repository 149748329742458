import {
  SERVICEFORMCLOSE_REQUEST,
  SERVICEFORMCLOSE_SUCCESS,
  SERVICEFORMCLOSE_FAILURE,
  FORCECLOSEREQUEST_REQUEST,
  FORCECLOSEREQUEST_SUCCESS,
  FORCECLOSEREQUEST_FAILURE,
} from './constants';

import {combineReducers} from 'redux';

const isFetchingServices = (state = false, action) => {
  switch (action.type) {
    case SERVICEFORMCLOSE_REQUEST:
      return true;
    case SERVICEFORMCLOSE_SUCCESS:
    case SERVICEFORMCLOSE_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataServices = (state = null, action) => {
  switch (action.type) {
    case SERVICEFORMCLOSE_SUCCESS:
      return action.payload;
    case FORCECLOSEREQUEST_SUCCESS:
      return (
        Array.isArray(state.serviceForm) &&
        state.map((serviceForm) => {
          if (serviceForm.mobilityId === action.payload.serviceDetailsId) {
            return {
              ...serviceForm,
              status: 'Pending',
              message: '',
            };
          }
          return serviceForm;
        })
      );
    default:
      return state;
  }
};

const failureServices = (state = null, action) => {
  switch (action.type) {
    case SERVICEFORMCLOSE_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasServiceFetched = (state = false, action) => {
  switch (action.type) {
    case SERVICEFORMCLOSE_REQUEST:
      return false;
    case SERVICEFORMCLOSE_SUCCESS:
    case SERVICEFORMCLOSE_FAILURE:
      return true;
    default:
      return state;
  }
};
const isClosingServiceForm = (state = false, action) => {
  switch (action.type) {
    case FORCECLOSEREQUEST_REQUEST:
      return true;
    case FORCECLOSEREQUEST_SUCCESS:
    case FORCECLOSEREQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const closingServiceData = (state = null, action) => {
  switch (action.type) {
    case FORCECLOSEREQUEST_SUCCESS:
      return action;
    default:
      return state;
  }
};

const failureServiceClose = (state = false, action) => {
  switch (action.type) {
    case FORCECLOSEREQUEST_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasServiceForceClosed = (state = false, action) => {
  switch (action.type) {
    case FORCECLOSEREQUEST_SUCCESS:
      return true;
    default:
      return state;
  }
};
export default combineReducers({
  isFetchingServices,
  dataServices,
  failureServices,
  hasServiceFetched,
  hasServiceForceClosed,
  failureServiceClose,
  isClosingServiceForm,
  closingServiceData,
});
