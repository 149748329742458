import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {ROUTES, RouteGuard} from '../routes';
import {useDispatch, useSelector} from 'react-redux';
import {Auth} from 'aws-amplify';
import {loginSuccess} from '../../modules/auth/action';
import {useIdleTimer} from 'react-idle-timer';
import {getAuthIsAuthenticated} from '../../modules/auth/selectors';
import {Modal} from 'antd';
import 'antd/dist/antd.css';
import {signOut} from '../../modules/auth/action';

const App = (props) => {
  const dispatch = useDispatch();
  const [shouldTryAutoLogin, setShouldTryAutoLogin] = useState(true);
  const isUserAuthenticated = useSelector(getAuthIsAuthenticated);

  function countDown() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: 'Information',
      content: `You have stayed idle for more than 20 minutes and plese login again in ${secondsToGo} second.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `You have stayed idle for more than 20 minutes and plese login again in ${secondsToGo} second.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  const handleOnIdle = () => {
    if (isUserAuthenticated) {
      countDown();
      dispatch(signOut());
    }
  };
  useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  useEffect(() => {
    if (shouldTryAutoLogin) {
      setShouldTryAutoLogin(false);
      Auth.currentAuthenticatedUser()
        .then((user) => {
          dispatch(loginSuccess(user));
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch, shouldTryAutoLogin]);

  return (
    <Router>
      <Switch>
        {ROUTES.map((route) => (
          <RouteGuard
            exact={route.exact}
            guard={route.guard}
            isAuthenticated={props.isAuthenticated}
            key={route.id}
            path={route.path}>
            <route.component />
          </RouteGuard>
        ))}
      </Switch>
    </Router>
  );
};

// App.propTypes = {
//   auth: PropTypes.object.isRequired,
// };

export default App;
