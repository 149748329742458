import {
  DASHBOARD_REQUEST,
  DASHBOARD_FAILURE,
  DASHBOARD_SUCCESS,
  DASHBOARDSTATUS_REQUEST,
  DASHBOARDSTATUS_SUCCESS,
  DASHBOARDSTATUS_FAILURE,
} from './constants';

import {combineReducers} from 'redux';

const isFetchingDashboard = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return true;
    case DASHBOARD_SUCCESS:
    case DASHBOARD_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataDashboard = (state = null, action) => {
  switch (action.type) {
    case DASHBOARD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureDashboard = (state = null, action) => {
  switch (action.type) {
    case DASHBOARD_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDashboardFetched = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return false;
    case DASHBOARD_SUCCESS:
    case DASHBOARD_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingDashboardCountOnStatus = (state = false, action) => {
  switch (action.type) {
    case DASHBOARDSTATUS_REQUEST:
      return true;
    case DASHBOARDSTATUS_SUCCESS:
    case DASHBOARDSTATUS_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataDashboardCount = (state = null, action) => {
  switch (action.type) {
    case DASHBOARDSTATUS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureDashboardCount = (state = null, action) => {
  switch (action.type) {
    case DASHBOARDSTATUS_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDashboardCountFetched = (state = false, action) => {
  switch (action.type) {
    case DASHBOARDSTATUS_REQUEST:
      return false;
    case DASHBOARDSTATUS_SUCCESS:
    case DASHBOARDSTATUS_FAILURE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  isFetchingDashboard,
  dataDashboard,
  failureDashboard,
  hasDashboardFetched,
  isFetchingDashboardCountOnStatus,
  dataDashboardCount,
  failureDashboardCount,
  hasDashboardCountFetched,
});
