import Amplify, {Auth} from 'aws-amplify';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FORCE_CHANGE_PASSWORD,
  LOGOUT_Failure,
} from './constants';
import {message} from 'antd';

export const loginSuccess = (payload) => {
  const accessToken =
    (payload.signInUserSession &&
      payload.signInUserSession.idToken &&
      payload.signInUserSession.idToken.jwtToken) ||
    null;
  const user = {
    cognitoUser: payload,
    email: payload.username,
    accessToken,
    challengeName: payload.challengeName,
  };
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};
export const changePasswordRequired = (payload) => {
  const accessToken =
    (payload.signInUserSession &&
      payload.signInUserSession.idToken &&
      payload.signInUserSession.idToken.jwtToken) ||
    null;
  const user = {
    cognitoUser: payload,
    email: payload.username,
    accessToken,
    challengeName: payload.challengeName,
  };
  return {
    type: FORCE_CHANGE_PASSWORD,
    user,
  };
};

export const signIn = ({username, password}) => {
  return (dispatch) => {
    return Auth.signIn(username, password)
      .then((user) => {
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
          ? dispatch(changePasswordRequired(user))
          : dispatch(loginSuccess(user));
        message.success({
          content: 'Login Successful !',
          className: 'custom-class',
          style: {
            fontFamily: 'arial',
            fontWeight: 400,
            color: '#797878',
          },
        });
      })
      .catch((err) => dispatch({type: LOGIN_FAILURE, error: {err}}));
  };
};
export const signOut = () => {
  return (dispatch) => {
    return Auth.signOut()
      .then(() => dispatch({type: LOGOUT, global: true}))
      .catch((err) => dispatch({type: LOGOUT_Failure, error: {err}}));
  };
};
