import {createSelector} from 'reselect';

export const getReport = ({Reports}) => Reports;

export const getIsFetchingReports = createSelector(
  getReport,
  (getReport) => getReport.isFetchingReport,
);
export const getIsFetchingReportsError = createSelector(
  getReport,
  (getReport) => getReport.hasReportFetchedError,
);
export const getReportsError = createSelector(
  getReport,
  (getReport) => getReport.failureReport,
);
export const getReportData = createSelector(
  getReport,
  (getReport) => getReport.reportData,
);
