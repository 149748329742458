import {createAction} from 'redux-api-middleware';
import {
  TERRITORYFILTER_FAILURE,
  TERRITORYFILTER_REQUEST,
  TERRITORYFILTER_SUCCESS,
} from './constants';
import {USER_API_URL} from '../../constants/envVariables';

export const getTerritoryDetails = (accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/BranchHierarchy`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      TERRITORYFILTER_REQUEST,
      TERRITORYFILTER_SUCCESS,
      TERRITORYFILTER_FAILURE,
    ],
  });
};
