export const serviceCallCloseColumns = [
  {
    title: 'Mobility Id',
    dataIndex: 'mobilityId',
    key: 'id',
    sorter: {
      compare: (a, b) => parseFloat(a.mobilityId) / parseFloat(b.mobilityId),
    },
  },
  {
    title: 'FollowUp Call Id',
    dataIndex: 'followupCallId',
    key: 'id',
    sorter: {
      compare: (a, b) => a.followupCallId / b.followupCallId,
    },
  },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  //   key: 'id',
  //   sorter: (a, b) => {
  //     a = a.status === null ? '' : a.status;
  //     b = b.status === null ? '' : b.status;
  //     return a.localeCompare(b);
  //   },
  // },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'id',
    // width: 300,
    // ellipsis: true,
  },
];
