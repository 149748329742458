import {REPORT_REQUEST, REPORT_SUCCESS, REPORT_FAILURE} from './constants';
import {combineReducers} from 'redux';

const isFetchingReport = (state = false, action) => {
  switch (action.type) {
    case REPORT_REQUEST:
      return true;
    case REPORT_SUCCESS:
    case REPORT_FAILURE:
      return false;
    default:
      return state;
  }
};

const reportData = (state = null, action) => {
  switch (action.type) {
    case REPORT_SUCCESS:
      return action.payload;
    case REPORT_REQUEST:
    case REPORT_FAILURE:
      return null;
    default:
      return state;
  }
};

const failureReport = (state = null, action) => {
  switch (action.type) {
    case REPORT_FAILURE:
      return action.payload;
    case REPORT_REQUEST:
    case REPORT_SUCCESS:
      return null;
    default:
      return state;
  }
};

const hasReportFetchedError = (state = false, action) => {
  switch (action.type) {
    case REPORT_FAILURE:
      return true;
    case REPORT_REQUEST:
    case REPORT_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isFetchingReport,
  reportData,
  failureReport,
  hasReportFetchedError,
});
