import {combineReducers} from 'redux';
import {
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_SESSSION,
  FORCE_CHANGE_PASSWORD,
  LOGIN_FAILURE,
} from './constants';
import user from './user/reducer';

// @ToDo make state = false when login is done
const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case UPDATE_SESSSION:
      return true;
    case LOGOUT:
    case FORCE_CHANGE_PASSWORD:
    case LOGIN_FAILURE:
      return false;
    default:
      return state;
  }
};
const isPasswordChangeRequired = (state = false, action) => {
  switch (action.type) {
    case FORCE_CHANGE_PASSWORD:
      return true;
    default:
      return state;
  }
};
const getLoginError = (state = null, action) => {
  switch (action.type) {
    case LOGIN_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const isLoginFailed = (state = false, action) => {
  switch (action.type) {
    case LOGIN_FAILURE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  isAuthenticated,
  user,
  isPasswordChangeRequired,
  getLoginError,
  isLoginFailed,
});
