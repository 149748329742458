import React, {useEffect, useState} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {getDashboard} from '../../modules/dashboard/action';
import {
  Table,
  Col,
  Row,
  message,
  Input,
  Button,
  Menu,
  Dropdown,
  Tooltip,
  DatePicker,
} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {useSelector, useDispatch} from 'react-redux';
import {
  getServiceFormClose,
  forceCloseService,
} from '../../modules/serviceFormClose/action';
import {
  getServiceFormCloseData,
  isServiceFormCloseFetching,
  isServiceFormForceClosed,
  hasForceCloseService,
  isForceClosingService,
  getServiceFormCloseCsvData,
} from '../../modules/serviceFormClose/selectors';
import {serviceCallCloseColumns} from '../../data/serviceCallClose';
import moment from 'moment';
import {Parser} from 'json2csv';

export const ServiceCallClose = () => {
  const authUser = useSelector(getAuthUserData);
  const dispatch = useDispatch();
  const mainData = useSelector(getServiceFormCloseData);
  const fetchingServices = useSelector(isServiceFormCloseFetching);
  const [filterData, setFilteredData] = useState();
  const hasServiceClosing = useSelector(isServiceFormForceClosed);
  const SucessForceCloseData = useSelector(hasForceCloseService);
  const [forceCloseClick, setForceCloseClick] = useState(false);
  const isForceCloseServiceRequest = useSelector(isForceClosingService);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [filteringCol, setFilteringCol] = useState({
    name: 'Select Filter',
    key: 'filter',
  });
  const RefreshData = () => {
    dispatch(getServiceFormClose(authUser.accessToken));
  };

  const filterDataOnSelectedCol = (col, value) => {
    let colsplit = col.split('.');
    if (filterData && value.length > 0) {
      if (colsplit.length === 3) {
        setFilteredData(
          filterData.filter((y) =>
            (y[colsplit[0]][colsplit[1]][colsplit[2]] + '').includes(value),
          ),
        );
      } else {
        setFilteredData(
          filterData.filter((y) => (y[col] + '').includes(value)),
        );
      }
    } else {
      setFilteredData(mainData);
    }
  };

  const failedCallCsvData = useSelector(getServiceFormCloseCsvData);

  useEffect(() => {
    if (mainData) {
      setFilteredData(
        mainData.map((row, index) => {
          return {...row, id: index};
        }),
      );
    }
  }, [mainData]);

  useEffect(() => {
    dispatch(getServiceFormClose(authUser.accessToken));
  }, [dispatch, authUser.accessToken]);

  const forceClose = (value, record) => {
    dispatch(forceCloseService(record.mobilityId, authUser.accessToken));
    setForceCloseClick(!forceCloseClick);
  };

  useEffect(() => {
    if (forceCloseClick && SucessForceCloseData) {
      if (!isForceCloseServiceRequest) {
        message.success({
          content: 'Request Submitted successfully!!',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        });
        setForceCloseClick(!forceCloseClick);
      }
    }
  }, [forceCloseClick, isForceCloseServiceRequest, SucessForceCloseData]);

  const updatedColumns = [
    ...serviceCallCloseColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'id',
      width: '12%',
      render: (text, record) => (
        <Button
          disabled={record.status !== 'Error'}
          onClick={(e) => {
            forceClose(e, record);
          }}
          style={{
            background: record.status !== 'Error' ? '#cccccc' : '#fd560eb5',
            border: record.status !== 'Error' ? '#cccccc' : '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Force Close
        </Button>
      ),
    },
    {
      title: 'Failure Date',
      dataIndex: 'createdOn',
      key: 'id',
      render: (createdOn) => {
        return <p>{moment(createdOn).format('DD/MM/YYYY hh:mm A')}</p>;
      },
      sorter: {
        compare: (a, b) =>
          moment(a.createdOn).format('DD/MM/YYYY hh:mm A') /
          moment(b.createdOn).format('DD/MM/YYYY hh:mm A'),
      },
    },
    {
      title: 'Retrial Date',
      dataIndex: 'updatedOn',
      key: 'id',
      render: (updatedOn) => {
        return <p>{moment(updatedOn).format('DD/MM/YYYY hh:mm A')}</p>;
      },
      sorter: {
        compare: (a, b) =>
          moment(a.updatedOn).format('DD/MM/YYYY hh:mm A') /
          moment(b.updatedOn).format('DD/MM/YYYY hh:mm A'),
      },
    },
  ];

  const dropDownContent = () => {
    let filterColumn = [
      {name: 'Mobility Id', key: 'mobilityId'},
      {
        name: 'Follow Up Call Id',
        key: 'followupCallId',
      },
    ];
    let returnable = (
      <Menu>
        {filterColumn.map((col, index) => {
          return (
            <Menu.Item
              key={String(index)}
              onClick={() => {
                setFilteringCol(col);
              }}>
              {col.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return returnable;
  };

  const onExportFailedCallsDataClick = () => {
    console.log('Just clicked');
    if (failedCallCsvData) {
      const headers = Object.keys(failedCallCsvData[0]);
      const parser = new Parser(headers);
      const csv = parser.parse(failedCallCsvData);
      var blob = new Blob([csv], {type: 'text/csv'});
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveBlob(
          blob,
          `Failed_Service_Calls_Data_${new Date()}.csv`,
        );
      else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
        a.download = `Failed_Service_Calls_Data_${new Date()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  };

  return (
    <MainLayout>
      <div className="contentLayout">
        <div>
          <Row>
            <Col span={4}>
              <span className="contentHeader">Failed Call</span>
            </Col>
            <Col span={3}>
              <Button
                onClick={() => RefreshData()}
                style={{
                  background: '#38a169',
                  border: '#38a169',
                  fontWeight: '550',
                  float: 'left',
                  marginRight: '10px',
                }}
                type="primary">
                Refresh
              </Button>
            </Col>
            {/* <Col span={4} style={{textAlign: 'center'}}>
              <DatePicker
                onChange={(value) => {
                  dispatch(
                    getDashboard( //getServiceFormClose
                      authUser.accessToken,
                      'Active',
                      value.format('YYYY-MM-DD'),
                    ),
                  );
                }}
              />
            </Col> */}
            <Col span={4}>
              <Button
                onClick={() => onExportFailedCallsDataClick()}
                style={{
                  background: '#38a169',
                  border: '#38a169',
                  fontWeight: '550',
                  float: 'right',
                  marginRight: '10px',
                }}
                type="primary">
                Export Failed Calls Data
              </Button>
            </Col>
            <Col span={5}>
              <Tooltip title="Filter By Column">
                <Dropdown
                  onVisibleChange={() => {
                    setDropDownVisible(!dropDownVisible);
                  }}
                  overlay={dropDownContent}
                  // placement="topCenter"
                  visible={dropDownVisible}>
                  <Button onClick={(e) => e.preventDefault()}>
                    {filteringCol.name}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Tooltip>
            </Col>

            <Col span={7}>
              <Input
                onChange={(e) =>
                  filterDataOnSelectedCol(filteringCol.key, e.target.value)
                }
                placeholder="Enter Search Value"
                style={{float: 'right'}}
                width="100px"
              />
            </Col>
          </Row>
        </div>
        <Table
          columns={updatedColumns}
          dataSource={filterData}
          loading={fetchingServices || hasServiceClosing}
          pagination={true}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
