import {Auth} from 'aws-amplify';
import {
  FORGOT_PASSWORD_VERIFICATION_CODE_SUCCESS,
  FORGOT_PASSWORD_VERIFICATION_CODE_FAILURE,
} from './constants';

export const forgotPasswordSendCode = ({Username}) => {
  return (dispatch) => {
    return Auth.forgotPassword(Username)
      .then((user) => {
        dispatch({type: FORGOT_PASSWORD_VERIFICATION_CODE_SUCCESS, user});
      })
      .catch((err) =>
        dispatch({type: FORGOT_PASSWORD_VERIFICATION_CODE_FAILURE, err}),
      );
  };
};
